<template>
  <HeaderHome />
  <FiltroEvento />
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-12">
          <h1>Calendário de eventos</h1>
          <div id="cphCorpo_lista_eventos">

            <div class="col-md-4 event-list" v-for="(event, index) in events" :key="index">
              <div class="event" data-toggle="tooltip" data-placement="top" title="" :data-original-title="event.name">
                <v-link :href="'/evento/' + event.id + '/' + event.slug + '/'" :title="event.name">
                  <img :src="event.image" :alt="event.name">

                  <div class="card-header" v-html="status(event)"></div>
                </v-link>
                <h3><v-link :href="'/evento/' + event.id + '/' + event.slug + '/'">{{ event.name }}</v-link></h3>
                <h5>
                  <v-link :href="'/evento/' + event.id + '/' + event.slug + '/'">
                    <span class="glyphicon glyphicon-calendar"></span> {{ event.start_at }}
                  </v-link>
                </h5>
              </div>
            </div>

          </div>
          <Atalhos />
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "../components/HeaderHome.vue";
import FiltroEvento from "../components/FiltroEvento.vue";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import VLink from "@/components/VLink";
import Atalhos from "@/components/Atalhos";
import Events from "../api/Events";

export default defineComponent({
  name: "CalendarioPage",
  components: {
    HeaderHome,
    VLink,
    FiltroEvento,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
  },
  data() {
    return {
      events: [],
    };
  },
  methods: {
    status_old(event) {
      let today = new Date();
      let start_date = new Date(event.subscription_start);
      let end_date = new Date(event.subscription_end);
      if (start_date <= today && end_date >= today) {
        return true;
      } else {
        return false;
      }
    },

    status(event) {
      return Events.get_status(event);
    },
  },
  mounted() {
    Events.get_events().then((request) => {
      if (request.status == 200) {
        this.events = request.data;
      }
    });
  }
});
</script>

<style></style>
